import React, { useState, useEffect } from "react";
import { Button, Input, Form, Checkbox, Alert, notification } from "antd";
import Cookies from "js-cookie";
import "./Login.css";
import logoTop from "../assets/logos/winglet_logo.png";
import logoFooter from "../assets/logos/powered_by.png";
import loginImage from "../assets/images/airplanewinglet.png";
import api from "../services/api";
import ChangePasswordModal from "../components/ChangePasswordModal";
import axios from "axios";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [form] = Form.useForm(); // Hook do Ant Design para gerenciar o formulário
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  useEffect(() => {
    // Verifica se há uma matrícula salva nos cookies
    const savedMatricula = Cookies.get("rememberedMatricula");
    if (savedMatricula) {
      form.setFieldsValue({ matricula: savedMatricula });
    }
  }, [form]);

  const getDeviceInfo = () => {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;

    return {
      userAgent,
      platform,
    };
  };

  const getLocationFromAPI = async () => {
    try {
      const response = await axios.get("https://freeipapi.com/api/json/");
      const data = await response.data;

      if (response.status === 200) {
        return {
          country: data.countryName,
          region: data.regionName,
          city: data.cityName,
        };
      } else {
        throw new Error("Falha ao obter localização.");
      }
    } catch (error) {
      console.error("Erro ao obter localização:", error);
      return null;
    }
  };

  const handleLogin = async (values) => {
    const { matricula, senha, remember } = values;
    setLoading(true);
    setAlert(null);

    try {
      // Captura informações de dispositivo
      const deviceInfo = getDeviceInfo();

      // Captura localização do usuário
      const location = await getLocationFromAPI();

      const response = await api.post(
        "/login",
        {
          matricula,
          senha,
          equipamento: `${deviceInfo.platform} - ${deviceInfo.userAgent}`, // Informações do equipamento
          localizacao: location
            ? `País: ${location.country}, Cidade: ${location.city}, Região: ${location.region}`
            : "Localização não disponível",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true, // Para cookies ou credenciais
        }
      );

      // Salvar token no localStorage
      localStorage.setItem("token", response.data.token);
      sessionStorage.setItem("response", response.data.user.isFirstAccess);

      // Lembrar matrícula se o checkbox estiver marcado
      if (remember) {
        Cookies.set("rememberedMatricula", matricula, { expires: 7 });
      } else {
        Cookies.remove("rememberedMatricula");
      }

      if (response.data.user.isFirstAccess === 1) {
        setShowPasswordModal(true);
      } else if (
        response.data.user.nivel_acesso === "user" &&
        response.data.userModules.length === 1 &&
        response.data.userModules[0].name === "equipamentos"
      ) {
        window.location.href = "/deliveryequipamentos";
      } else if (
        response.data.user.nivel_acesso === "user" &&
        response.data.userModules.length === 1 &&
        response.data.userModules[0].name === "guarda-volumes"
      ) {
        window.location.href = "/guardavolumes";
      } else {
        window.location.href = "/modules";
      }
    } catch (error) {
      if (
        error.response?.status === 401 &&
        error.response?.data?.message === "wrongcredentials"
      ) {
        form.resetFields(); // Reset form
        notification.error({
          message: "Credenciais inválidas. Tente novamente.",
          placement: "bottomLeft",
          duration: 5,
          showProgressBar: true,
        });
      } else if (
        error.response?.status === 401 &&
        (error.response?.data?.message === "inactive" ||
          error.response?.data?.message === "deleted")
      ) {
        form.resetFields(); // Reset form
        notification.error({
          message: "Procure o administrador do sistema.",
          placement: "bottomLeft",
          duration: 5,
          showProgressBar: true,
        });
      } else {
        notification.error({
          message:
            "Não foi possível realizar o login. Tente novamente mais tarde.",
          placement: "bottomLeft",
          duration: 5,
          showProgressBar: true,
        });
        form.resetFields(); // Reset form
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      {/* Coluna Esquerda */}
      <div className="login-form-container">
        <img src={logoTop} alt="Logo Superior" className="logo-top" />
        <ChangePasswordModal
          open={showPasswordModal}
          onClose={() => setShowPasswordModal(false)}
          matricula={form.getFieldValue("matricula")}
        />
        <Form
          form={form}
          onFinish={handleLogin}
          className="login-form"
          onValuesChange={() => setAlert(null)} // Limpa alertas ao alterar os campos
          preserve={false}
        >
          <Form.Item
            name="matricula"
            rules={[
              { required: true, message: "Por favor, insira sua matrícula!" },
            ]}
          >
            <Input placeholder="Matrícula Orbital" size="large" />
          </Form.Item>
          <Form.Item
            name="senha"
            rules={[
              { required: true, message: "Por favor, insira sua senha!" },
            ]}
          >
            <Input.Password placeholder="Senha" size="large" />
          </Form.Item>
          <Form.Item
            name="remember"
            valuePropName="checked"
            className="remember-checkbox"
          >
            <Checkbox>Lembrar meu login</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              block
              size="large"
              className="button_login"
              disabled={loading}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
        <div className="forgot-password">
          <a href="#forgot">Esqueci minha senha</a>
        </div>
        {alert && (
          <Alert message={alert} type="error" showIcon className="alert-box" />
        )}
        <img src={logoFooter} alt="Footer Logo" className="logo-footer" />
      </div>

      {/* Coluna Direita */}
      <div className="login-image-container">
        <img src={loginImage} alt="Imagem de Login" className="login-image" />
      </div>
    </div>
  );
};

export default Login;
