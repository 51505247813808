import React, {
  lazy,
  Suspense,
  useState,
  useEffect,
  startTransition,
} from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./index.css";
import Login from "./pages/Login";
import PrivateRoute from "./contexts/PrivateRoute";
import Loading from "./components/Loading";

// Lazy load components
const ModuleSelection = lazy(() => import("./pages/ModuleSelection"));
const EquipmentManager = lazy(() => import("./pages/EquipmentManagement"));
const EquipmentTransactions = lazy(() =>
  import("./pages/EquipmentTransactions")
);
const LockerManagement = lazy(() => import("./pages/LockerManagement"));
const UserManagement = lazy(() => import("./pages/UserManagement"));
const Calibracao = lazy(() => import("./pages/CalibracaoEquipamentos"));

function AppRoutes() {
  const location = useLocation();
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    const controller = new AbortController();

    startTransition(() => {
      setIsPending(true);

      // Simulate a minimum loading time (optional)
      const timeout = setTimeout(() => {
        setIsPending(false);
      }, 300); // Adjust this time as needed

      return () => {
        controller.abort();
        clearTimeout(timeout);
      };
    });

    return () => {
      controller.abort();
    };
  }, [location]);

  return (
    <>
      <Routes location={location}>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/modules"
          element={
            <PrivateRoute allowedRoles={["admin", "superadmin"]}>
              <ModuleSelection />
            </PrivateRoute>
          }
        />
        <Route
          path="/equipamentos"
          element={
            <PrivateRoute>
              <EquipmentManager />
            </PrivateRoute>
          }
        />
        <Route
          path="/deliveryequipamentos"
          element={
            <PrivateRoute>
              <EquipmentTransactions />
            </PrivateRoute>
          }
        />
        <Route
          path="/guardavolumes"
          element={
            <PrivateRoute>
              <LockerManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/usuarios"
          element={
            <PrivateRoute allowedRoles={["admin", "superadmin"]}>
              <UserManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/calibracao"
          element={
            <PrivateRoute>
              <Calibracao />
            </PrivateRoute>
          }
        />
      </Routes>
      {isPending && <Loading />}
    </>
  );
}

function App() {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <AppRoutes />
      </Suspense>
    </Router>
  );
}

export default App;
