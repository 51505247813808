import React from "react";
import { Spin, Row, Col } from "antd";

function Loading() {
  return (
    <Row
      justify="center"
      align="middle"
      style={{
        height: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        zIndex: 1000,
      }}
    >
      <Col>
        <Spin size="large" />
      </Col>
    </Row>
  );
}

export default Loading;
