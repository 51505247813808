import axios from "axios";
import { refreshToken } from "./authService";

const api = axios.create({
  //baseURL: "http://localhost:7554/api",
  baseURL: "/api", // /api em prod
  withCredentials: true, // Permite envio de cookies/sessões
});

// Interceptor para requisições
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor para respostas
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // Se o erro for 401 (Unauthorized) e não for uma tentativa de refresh
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        // Tenta obter novo token
        const newToken = await refreshToken();
        localStorage.setItem("token", newToken);

        // Atualiza o header com novo token
        originalRequest.headers.Authorization = `Bearer ${newToken}`;

        // Refaz a requisição original com o novo token
        return api(originalRequest);
      } catch (error) {
        // Se não conseguir renovar o token, redireciona para login
        localStorage.removeItem("token");
        window.location.href = "/login";
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
